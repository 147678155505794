import React from 'react';
import { graphql } from 'gatsby';

import TemplateDefault from './default';
import getParagraph from '../components/paragraphs/paragraph-loader';
import AtomTitle from '../components/atom/title';
import AtomDrupalContent from '../components/atom/drupal-content';
import AtomPaLink from '../components/atom/plain-link';
import SEO from '../components/seo';
import GardenExplorer from '../components/organism/garden-explorer';
import topOrange from '../images/top-orange.svg';
import topGreen from '../images/top-green.svg';

export default function TopicPage({ data }) {
  const urlPath = process.env.GATSBY_IMAGE_BASE || process.env.DRUPAL_API_URL;
  const paragraphs =
    data.nodePage.relationships?.paragraphs?.map(getParagraph) ?? [];

  function image() {
    if (data.nodePage.relationships.image.relationships != null) {
      return (
        urlPath +
        data.nodePage.relationships.image.relationships.field_media_image_4.uri
          .url
      );
    }
    return null;
  }
  function promotionImage() {
    if (
      data.nodePage.relationships.promotionalBlock.relationships.image
        .relationships != null
    ) {
      return (
        urlPath +
        data.nodePage.relationships.promotionalBlock.relationships.image
          .relationships.field_media_image_4.uri.url
      );
    }
    return null;
  }

  const buttons = data.nodePage.relationships.promotionalBlock.links.map(
    (link, index) => (
      <AtomPaLink key={`button-${index}`} text={link.title} uri={link.uri} />
    ),
  );

  const bgClass =
    data.nodePage.relationships.promotionalBlock.bgColour === 'green'
      ? 'tw-bg-anbg-dark-green'
      : 'tw-bg-anbg-orange';

  const topShape =
    data.nodePage.relationships.promotionalBlock.bgColour === 'green'
      ? topGreen
      : topOrange;

  return (
    <TemplateDefault title={data.nodePage.title}>
      <main className="tw-bg-anbg-cream">
        <section className="tw-relative tw-h-full tw-w-[100vw] tw-py-20 tw-px-4 xl:tw-ml-[235px] xl:tw-w-[calc(100vw-235px)] xl:tw-py-24 xl:tw-px-10 2xl:tw-py-32">
          <div className=" tw-flex  ">
            <div className="tw-mx-auto tw-w-full tw-max-w-screen-2xl tw-items-center tw-justify-center tw-text-anbg-offblack md:tw-space-x-10 xl:tw-flex 2xl:tw-space-x-20 ">
              <div className="tw-flex tw-flex-col xl:tw-w-1/2">
                <div className="tw-pb-8">
                  <AtomTitle title={data.nodePage.title} />
                </div>
                {data.nodePage.body?.processed && (
                  <AtomDrupalContent content={data.nodePage.body.processed} />
                )}

                {data.nodePage.url && (
                  <AtomPaLink
                    uri={data.nodePage.url.uri}
                    text={data.nodePage.url.title}
                    className="lh-btn-yellow"
                  />
                )}
              </div>
              <div className="xl:tw-w-1/2">
                <img src={image()} alt="" className="tw-w-full" />
              </div>
            </div>
          </div>
        </section>
        <GardenExplorer
          image={data.nodePage.explorerImage}
          text={data.nodePage.explorerText}
        />

        <div className="xl:tw-ml-[235px]">{paragraphs}</div>
        <section
          className={`tw-relative tw-mt-32 tw-px-4 tw-pt-10 xl:tw-ml-[235px] xl:tw-mt-48 ${bgClass}`}
        >
          <div className="tw-absolute tw-bottom-[100%] tw-left-0 tw-z-10 tw-w-full ">
            <img src={topShape} alt="" />
          </div>

          <div className="tw-mx-auto tw-w-full tw-max-w-screen-2xl tw-items-center tw-justify-center tw-text-anbg-cream xl:tw-flex ">
            <div className="tw-flex tw-flex-col tw-pb-10 xl:tw-w-1/2 xl:tw-pb-32">
              <h2 className="textLarge tw-pb-10">
                {data.nodePage.relationships.promotionalBlock.title}
              </h2>
              <div className="tw-prose tw-max-w-none tw-text-white xl:tw-prose-xl">
                <p className="md:tw-w-2/3">
                  {data.nodePage.relationships.promotionalBlock.description}
                </p>
              </div>
              <div>{buttons}</div>
            </div>
            <div className="xl:tw-w-1/3">
              <img src={promotionImage()} alt="" className="tw-w-full" />
            </div>
          </div>
        </section>
      </main>
    </TemplateDefault>
  );
}

export const query = graphql`
  query ($drupalId: String!) {
    nodePage: nodeAnbgLhTopic(drupal_id: { eq: $drupalId }) {
      title
      body {
        processed
      }
      url: field_anbg_lh_url {
        title
        uri
      }
      explorerImage: field_anbg_lh_explorer_img
      explorerText: field_anbg_lh_explorer_text
      relationships {
        paragraphs: field_anbg_lh_content {
          type: __typename
          ...ParagraphTwoThirds
          ...ParagraphResourceList
          ...ParagraphEmbedvideo
        }
        image: field_anbg_lh_image {
          ... on media__anbg_lh_inline_img {
            relationships {
              field_media_image_4 {
                uri {
                  url
                }
              }
            }
          }
        }

        promotionalBlock: field_anbg_lh_promotional_block {
          ... on paragraph__anbg_lh_promotional_block {
            title: field_anbg_lh_title
            links: field_anbg_lh_cta {
              title
              uri
            }
            description: field_anbg_description
            bgColour: field_anbg_lh_background_colour
            relationships {
              image: field_anbg_lh_img {
                ... on media__anbg_lh_inline_img {
                  relationships {
                    field_media_image_4 {
                      uri {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export function Head({ data }) {
  return <SEO title={data.nodePage.title} />;
}
